.component-all-settings {
    margin-top: 30px;
}

.tabs-menu {
    display: flex;

    .Polaris-Icon {
        margin-right: 10px;
    }
}