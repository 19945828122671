.flex-parent {
    text-align: center;
}

.left-heading {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.main-heading {
    font-weight: bold;
    font-size: 24px;
    margin: 10px auto;
}

.sub-heading {
    font-size: 16px;
    margin: 10px auto 35px;
}

.img-sec {
    width: 150px;
    height: 150px;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
}


.img-sec img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.pro-Name {
    font-weight: bold;
    font-size: 15px;
    margin: 5px auto 5px;
}

.pro-Price {
    font-size: 14px;
    margin: 5px auto 10px;
}

button.pro-Button {
    padding: 10px 20px;
    min-width: 120px;
    background: #dedede;
    border: 0px;
    box-shadow: unset;
    font-size: 14px;
}

.inner-flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 65%;
}

.Pro-Area {
    margin: 10px 0;
    width: 33.33%;
    padding: 15px;
}

.sale-Badge {
    position: absolute;
    top: 7px;
    left: -20px;
    background: red;
    font-size: 13px;
    padding: 2px 10px;
    transform: rotate(-45deg);
    color: #fff;
    width: 80px;
}

button.pro-Button.large {
    width: 100%;
    font-size: 110%;
}

button.pro-Button.small {
    min-width: unset;
    font-size: 80%;
    padding: 4px 8px;
}




@-webkit-keyframes flow {
    0% {
        left: -20px;
        opacity: 0;
    }

    50% {
        left: 100px;
        opacity: 0.3;
    }

    100% {
        left: 180px;
        opacity: 0;
    }
}

@keyframes flow {
    0% {
        left: -20px;
        opacity: 0;
    }

    50% {
        left: 100px;
        opacity: 0.3;
    }

    100% {
        left: 180px;
        opacity: 0;
    }
}

.glow {
    background: rgb(255, 255, 255);
    width: 40px;
    height: 100%;
    z-index: 999;
    position: absolute;
    -webkit-animation: flow 1.5s linear infinite;
    -moz-animation: flow 1.5s linear infinite;
    -webkit-transform: skew(20deg);
    -moz-transform: skew(20deg);
    -o-transform: skew(20deg);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 1) 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(1%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 1)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 1) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 1) 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 1) 100%);
    /* IE10+ */
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 1) 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
    /* IE6-9 */
    border-left: 1px solid #fff;
}



@media (max-width: 768px) {
    .inner-flex {
        max-width: 100% !important;
    }
}

@media (max-width: 480px) {
    .Pro-Area {
        width: 50% !important;
    }
}