.App {
  /*text-align: center;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Polaris-Frame__Navigation {
    top: auto;
}
.Polaris-Thumbnail--sizeMedium {
    width: 4rem;
}
.Polaris-Thumbnail {
    background: transparent;
}
.Polaris-Thumbnail::after {
    box-shadow: none;
}
.nav-menus a {
    padding-right: 30px;
}
.Polaris-Page__Content .Polaris-Page {
    padding-left: 1px;
	padding-right: 1px;
    padding-bottom: 8px;
}
.Polaris-Page-Header.Polaris-Page-Header--separator .Polaris-Page-Header__PrimaryActionWrapper button.Polaris-Button.Polaris-Button--primary {
    background: transparent;
    border: 0;
    box-shadow: none;
    color: #000;
}
.Polaris-Page-Header.Polaris-Page-Header--separator .Polaris-Page-Header__PrimaryActionWrapper button.Polaris-Button.Polaris-Button--primary span.Polaris-Button__Text {
    font-weight: 600;
}

.mainOffers ul.Polaris-ResourceList li.Polaris-ResourceList__ItemWrapper {
    background: #fff;
    margin-bottom: 15px;
    box-shadow: 0px 0px 8px -4px #ccc;
    padding: 2px 0;
}
.mainOffers ul.Polaris-ResourceList li.Polaris-ResourceList__ItemWrapper h3 {
    margin-bottom: 8px;
	font-weight: 600;
    font-size: 1.1em;
}
.mainOffers .Polaris-ResourceItem--persistActions .Polaris-ResourceItem__Actions {
    margin-top: 1.3rem;
}
.offerMeta .Polaris-Card {
    background: transparent;
    box-shadow: none;
    border: 0;
    float: right;
}
.offerMeta .Polaris-Card .Polaris-Card__Footer {
    padding: 0;
    margin-top: -22px;
}
.Polaris-ResourceItem:hover:not(.Polaris-ResourceItem--newDesignLanguage) {
    background-image: linear-gradient(#fff, #fff) !important;
}
.couponOffers > .Polaris-Card .Polaris-Card__Section > div > div > .Polaris-Label {
    width: 100%;
    text-align: center;
}

.button_wrap button[type=submit], .button_wrap button[type=button] {
    display: inline-block;
    /* margin: 0 20px 0 0; */
    min-width: 120px;
}
.popupUpsellOffers .button_wrap {
    text-align: right;
    margin-top: 30px;
}
.popupUpsellOffers .button_wrap button[type=submit], .popupUpsellOffers .button_wrap button[type=button] {
    margin: 0 0 0 20px;
}

.popupUpsellOffers .Polaris-FormLayout__Items .Polaris-FormLayout__Item:first-child {
	margin-left: 0;
}

.popupUpsellOffers .Polaris-Choice__HelpText {
    font-size: 1.2rem;
    line-height: 1.5;
}
.popupUpsellOffers .Polaris-Choice__Descriptions {
    padding-bottom: 10px;
}
.secondry-nav-menus.nav-menus {
    margin-top: -50px;
    margin-bottom: 30px;
}
.secondry-nav-menus.nav-menus a {
    color: #000;
    font-weight: 600;
}

@media (min-width: 40em){
	.Polaris-Page__Content .Polaris-Page h1.Polaris-DisplayText {
		font-size: 2.25rem;
		margin-top: -5px;
	}
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
