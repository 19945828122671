.cust-popu-outer button:focus {
    border-color: #ccc;
    outline: none;
}

.cust-popu-outer {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.cust-popup-open {
    height: 100%;
    width: 100%;
    display: flex;
    margin: 0;
}

.cust-popup-inner {
    max-width: 900px;
    margin: 0 auto;
    background: #fff;
    width: 100%;
}

.cust-popup-discount {
    padding: 15px 30px;
    font-size: 20px;
}

.cust-popup-head h3 {
    word-break: break-word;
    margin: 0;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 30px;
}

.cust-popup-head p {
    margin: 10px 0;
    word-break: break-word;
    font-weight: 600;
    font-size: 17px;
    line-height: 17px;
}

.cust-popup-head {
    padding: 30px 35px 0;
}

.cust-popup-footer {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    border-top: 1px solid #ddd;
    align-items: center;
}

.cust-btn {
    padding: 10px 20px;
    border: 1px solid #ccc;
    word-break: break-word;
    cursor: pointer;
}

.cust-popup-footer * {
    cursor: pointer;
}

.footer-text {
    color: #454545;
    text-decoration: none;
}

.cust-popup-body div {
    width: 50%;
}

.cust-popup-body {
    display: flex;
    flex-wrap: wrap;
    margin: 0 20px 30px;
    border: 1px solid #ddd;
}

.popup-image-box img {
    width: 100%;
}

.popup-detail-box {
    text-align: center;
    padding: 30px;
}

.cust-addtocart {
    background: #e48643;
    border: 1px solid #e48643;
    padding: 10px 20px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
}

.popup-detail-box h4,
.popup-detail-box p {
    word-break: break-word;
    font-weight: 600;
}

.popup-detail-box h4 {
    font-size: 18px;
    margin-bottom: 6px;
}

.popup-detail-box p {
    font-size: 22px;
    margin: 10px 0;
}

.popup-image-box {
    display: flex;
    align-items: center;
    align-content: center;
    padding: 20px;
}

.popup-mobile-preview .cust-popup-body {
    flex-direction: column;
    align-items: center;
}

.popup-mobile-preview .popup-image-box img {
    width: 60%;
    margin: auto;
}

.popup-mobile-preview .cust-popup-body {
    flex-direction: column;
    align-items: center;
}





@media(max-width: 580px) {
    .cust-popup-body div {
        width: 100%;
    }

    .popup-image-box img {
        width: 50%;
        margin: 0 auto;
    }

    .cust-popup-head h3 {
        font-size: 18px;
    }

    .cust-popup-head p {
        font-size: 15px;
    }
}