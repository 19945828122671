@font-face {
    /* font-family: 'PaymentFont';
    src: url('fonts/payment-fonts/paymentfont-webfont.eot') format('eot'),
        url('fonts/payment-fonts/paymentfont-webfont.ttf') format('ttf'),
        url('fonts/payment-fonts/paymentfont-webfont.woff') format('woff'),
        url('fonts/payment-fonts/paymentfont-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal; */

    font-family: PaymentFont;
    src: url(fonts/payment-fonts/paymentfont-webfont.eot);
    src: url(fonts/payment-fonts/paymentfont-webfont.eot?#iefix) format("embedded-opentype"), url(fonts/payment-fonts/paymentfont-webfont.woff) format("woff2"), url(fonts/payment-fonts/paymentfont-webfont.woff) format("woff"), url(fonts/payment-fonts/paymentfont-webfont.ttf) format("truetype"),
        url(fonts/payment-fonts/paymentfont-webfont.svg#paymentfont-webfont) format("svg");
    font-weight: 400;
}

.pf {
    display: inline-block;
    font: normal normal normal 14px/1 PaymentFont;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pf-amazon:before {
    content: "\f000";
}

.pf-american-express:before {
    content: "\f001";
}

.pf-american-express-alt:before {
    content: "\f002";
}

.pf-atm:before {
    content: "\f003";
}

.pf-bankomat:before {
    content: "\f004";
}

.pf-bank-transfer:before {
    content: "\f005";
}

.pf-bitcoin:before {
    content: "\f006";
}

.pf-bitcoin-sign:before {
    content: "\f007";
}

.pf-braintree:before {
    content: "\f008";
}

.pf-btc:before {
    content: "\f009";
}

.pf-card:before {
    content: "\f00a";
}

.pf-carta-si:before {
    content: "\f00b";
}

.pf-cash:before {
    content: "\f00c";
}

.pf-cash-on-delivery:before {
    content: "\f00d";
}

.pf-cb:before {
    content: "\f00e";
}

.pf-cirrus:before {
    content: "\f00f";
}

.pf-cirrus-alt:before {
    content: "\f010";
}

.pf-clickandbuy:before {
    content: "\f011";
}

.pf-credit-card:before {
    content: "\f012";
}

.pf-diners:before {
    content: "\f013";
}

.pf-discover:before {
    content: "\f014";
}

.pf-ec:before {
    content: "\f015";
}

.pf-eps:before {
    content: "\f016";
}

.pf-eur:before {
    content: "\f017";
}

.pf-facture:before {
    content: "\f018";
}

.pf-fattura:before {
    content: "\f019";
}

.pf-flattr:before {
    content: "\f01a";
}

.pf-giropay:before {
    content: "\f01b";
}

.pf-google-wallet:before {
    content: "\f01c";
}

.pf-google-wallet-alt:before {
    content: "\f01d";
}

.pf-gpb:before {
    content: "\f01e";
}

.pf-gratipay:before {
    content: "\f01f";
}

.pf-ideal:before {
    content: "\f020";
}

.pf-ils:before {
    content: "\f021";
}

.pf-inr:before {
    content: "\f022";
}

.pf-invoice:before {
    content: "\f023";
}

.pf-invoice-sign:before {
    content: "\f024";
}

.pf-invoice-sign-alt:before {
    content: "\f025";
}

.pf-invoice-sign-alt-o:before {
    content: "\f026";
}

.pf-invoice-sign-o:before {
    content: "\f027";
}

.pf-jcb:before {
    content: "\f028";
}

.pf-jpy:before {
    content: "\f029";
}

.pf-krw:before {
    content: "\f02a";
}

.pf-maestro:before {
    content: "\f02b";
}

.pf-maestro-alt:before {
    content: "\f02c";
}

.pf-mastercard:before {
    content: "\f02d";
}

.pf-mastercard-alt:before {
    content: "\f02e";
}

.pf-mastercard-securecode:before {
    content: "\f02f";
}

.pf-ogone:before {
    content: "\f030";
}

.pf-paybox:before {
    content: "\f031";
}

.pf-paylife:before {
    content: "\f032";
}

.pf-paypal:before {
    content: "\f033";
}

.pf-paypal-alt:before {
    content: "\f034";
}

.pf-paysafecard:before {
    content: "\f035";
}

.pf-postepay:before {
    content: "\f036";
}

.pf-quick:before {
    content: "\f037";
}

.pf-rechnung:before {
    content: "\f038";
}

.pf-ripple:before {
    content: "\f039";
}

.pf-rub:before {
    content: "\f03a";
}

.pf-skrill:before {
    content: "\f03b";
}

.pf-sofort:before {
    content: "\f03c";
}

.pf-square:before {
    content: "\f03d";
}

.pf-stripe:before {
    content: "\f03e";
}

.pf-truste:before {
    content: "\f03f";
}

.pf-try:before {
    content: "\f040";
}

.pf-unionpay:before {
    content: "\f041";
}

.pf-usd:before {
    content: "\f042";
}

.pf-verified-by-visa:before {
    content: "\f043";
}

.pf-verisign:before {
    content: "\f044";
}

.pf-visa:before {
    content: "\f045";
}

.pf-visa-electron:before {
    content: "\f046";
}

.pf-western-union:before {
    content: "\f047";
}

.pf-western-union-alt:before {
    content: "\f048";
}

.pf-wirecard:before {
    content: "\f049";
}

.pf-sepa:before {
    content: "\f04a";
}

.pf-sepa-alt:before {
    content: "\f04b";
}

.pf-apple-pay:before {
    content: "\f04c";
}

.pf-interac:before {
    content: "\f04d";
}

.pf-paymill:before {
    content: "\f04e";
}

.pf-dankort:before {
    content: "\f04f";
}

.pf-bancontact-mister-cash:before {
    content: "\f050";
}

.pf-moip:before {
    content: "\f051";
}

.pf-pagseguro:before {
    content: "\f052";
}

.pf-cash-on-pickup:before {
    content: "\f053";
}

.pf-sage:before {
    content: "\f054";
}

.pf-elo:before {
    content: "\f055";
}

.pf-elo-alt:before {
    content: "\f056";
}

.pf-payu:before {
    content: "\f057";
}

.pf-mercado-pago:before {
    content: "\f058";
}

.pf-mercado-pago-sign:before {
    content: "\f059";
}

.pf-payshop:before {
    content: "\f05a";
}

.pf-multibanco:before {
    content: "\f05b";
}

.pf-gratipay-sign:before {
    content: "\f05c";
}

.pf-six:before {
    content: "\f05d";
}

.pf-cashcloud:before {
    content: "\f05e";
}

.pf-interac-alt:before {
    content: "\f05f";
}

.pf-klarna:before {
    content: "\f060";
}

.pf-bitpay:before {
    content: "\f061";
}

.pf-venmo:before {
    content: "\f062";
}

.pf-visa-debit:before {
    content: "\f063";
}

.pf-alipay:before {
    content: "\f064";
}

.pf-diners-alt:before {
    content: "\f065";
}

.pf-hipercard:before {
    content: "\f066";
}

.pf-skrill-alt:before {
    content: "\f067";
}

.pf-shopify:before {
    content: "\f068";
}

.pf-direct-debit:before {
    content: "\f069";
}

.pf-sodexo:before {
    content: "\f06a";
}

.pf-bpay:before {
    content: "\f06b";
}

.pf-contactless:before {
    content: "\f06c";
}

.pf-contactless-alt:before {
    content: "\f06d";
}

.pf-eth:before {
    content: "\f06e";
}

.pf-ltc:before {
    content: "\f06f";
}

.pf-visa-pay:before {
    content: "\f070";
}

.pf-wechat-pay:before {
    content: "\f071";
}

.pf-amazon-pay:before {
    content: "\f072";
}

.pf-amazon-pay-alt:before {
    content: "\f073";
}

.advance-badges-wrapper {
    border: .1rem solid #c4cdd5;
    border-radius: 0.3rem;
    padding: 1.5rem 1rem;
    box-shadow: 0 0 0 1px transparent, 0 1px 0 0 rgba(22, 29, 37, 0.05);
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1rem;
    margin-top: 30px;
}

.badge-image {
    width: auto;
    max-width: 100%;
    max-height: 85px;
    display: block;
    margin: 10px 5px;
}

.badge-final-preview {
    width: 35%;
}

.badge-final-preview .message {
    margin: 10px 0px;
    line-height: normal;
}

.badge-images-wrapper {
    display: flex;
    /* grid-template-columns: repeat(3, 1fr); */
    flex-wrap: wrap;
    justify-content: flex-start;
}

.hide {
    display: none;
}

.icons-scroller {
    max-height: 400px;
    overflow-y: scroll;
    border: 1px solid #dedede;
    padding: 10px 2px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.badge-icon.pf {
    padding: 15px 10px;
    font-size: 40px;
}

.icons-inner {
    display: flex;
    flex-wrap: wrap;
}

.icons-inner .badge {
    text-align: center;
    width: 18%;
    margin: 1%;
    background: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
}

.icons-inner .badge.active {
    background: #6774c8;
    color: #fff;
}

.sort-div {
    display: flex;
    flex-direction: column;
    border: 1px solid #dedede;
    padding: 10px;
    border-radius: 5px;
}

.reorder {
    display: flex;
    align-items: center;
    margin: 5px 0;
    cursor: pointer;
}

.sort-div span.Polaris-Icon {
    display: inline-block;
    margin: 0 5px 0 0;
}

.trust-badges-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 5px auto 20px;
}

.trust-badges-wrapper .badge-icon.pf {
    font-size: 28px;
}

.card-heading-with-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

button.add-cart-btn {
    width: 100%;
    padding: 12px 10px;
    border: 0;
    background: #6774c8;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
}

.card-heading-with-toggle .Polaris-Button--pressed {
    background-color: #f4f5fa;
    box-shadow: inset 0 0 0.8rem rgba(92, 106, 196, .3);
}

.card-heading-with-toggle .Polaris-Button {
    color: #6774c8;
}

.badge-blk {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.badge-blk svg {
    width: 25px;
    position: absolute;
    top: 37%;
    bottom: 0;
    left: 41%;
    right: 0;
    display: none;
}

.badge-blk.active svg {
    display: inline-block;
}

.badge-blk.active img {
    opacity: 0.2;
    z-index: 9;
}

.badge-blk .Polaris-Icon {
    display: none;
}

.badge-blk .Polaris-Icon svg {
    display: inline-block;
}

.badge-blk.fixed.edit-select {
    opacity: 0.05;
    pointer-events: none;
}

.badge-blk.files.edit-select .Polaris-Icon {
    display: inline-block;
}

.badge-blk.files.edit-select .Polaris-Icon svg {
    fill: red;
}

.badge-blk.files.edit-select img {
    opacity: 0.5;
}

.badge-blk.files.edit-select>svg {
    display: none;
}

.badge-blk .delete-icon {
    cursor: pointer;
}

.badge-products-wrapper {
    max-height: 400px;
    overflow-y: auto;
    margin-top: 10px;
}

.images-spinner {
    text-align: center;
}


@media (max-width: 768px) {
    .badge-final-preview {
        width: 50%;
    }
}

@media (max-width: 640px) {
    .icons-inner .badge {
        width: 23%;
    }

    .badge-final-preview {
        width: 100%;
    }
}

@media (max-width: 450px) {
    .icons-inner .badge {
        width: 31.33%;
    }
}

@media (max-width: 345px) {
    .icons-inner .badge {
        width: 48%;
    }
}